<template>
  <div>
    <v-card :loading="isLoading" class="mb-5">
      <v-card-title>
        <h3 class="card-label">Periyodik Borçlandırma</h3>
        <v-col class="text-right" v-if="id">
          <rs-action
            :to="{
              name: 'definitions.periodical-assessments.edit',
              params: { id: id },
            }"
            v-if="id && can('edit-periodical-income') && disabled"
            title="Düzenle"
            icon
          >
            <v-icon>mdi-pencil</v-icon>
          </rs-action>

          <rs-action
            @click="showActionLogs"
            v-if="id && can('edit-periodical-income')"
            icon
            title="Düzenleme geçmişi"
          >
            <v-icon>mdi-information-variant</v-icon>
          </rs-action>
        </v-col>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="handleFormSubmit">
          <v-row cols="12" dense>
            <v-col sm="6">
              <rs-select
                :items="filteredIncomes"
                :label="$t('labels.debt_type')"
                :rules="[rules.required]"
                v-model="income_type_id"
                :readonly="disabled"
                :filled="disabled"
                return-object
                @input="setSelected($event)"
                id="periodic-income"
              />
            </v-col>
            <v-col sm="6">
              <rs-select
                :items="types"
                item-text="display_name"
                item-value="id"
                label="Tahakkuk Türü"
                v-model="formData.assessment_type_id"
                :rules="[rules.required]"
                :readonly="disabled"
                :filled="disabled"
                @change="handleAssessmentTypeChange"
              />
            </v-col>
            <v-col sm="6">
              <rs-select
                label="Borçlu Tipi"
                :rules="disabled ? [] : [rules.required]"
                v-model="formData.debitor_type_id"
                :items="debitorTypeList"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>
            <v-col sm="6">
              <rs-is-active
                v-model="formData.is_active"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>
            <v-col sm="6">
              <rs-select
                :items="autoAssessmentTimingTypeList"
                :label="$t('labels.assessment_timing_type')"
                :rules="[rules.required]"
                v-model="formData.issue_auto_assessment_timing_type_id"
                :readonly="disabled"
                :filled="disabled"
                required
              />
            </v-col>
            <v-col sm="6">
              <rs-text-field
                v-if="
                  [1, 2, 8, 9].indexOf(
                    formData.issue_auto_assessment_timing_type_id
                  ) > -1
                "
                type="number"
                min="1"
                max="31"
                step="1"
                :label="$t('labels.assessment_timing_day')"
                v-model="formData.issue_day"
                :rules="[rules.required]"
                required
                :readonly="disabled"
                :filled="disabled"
                hint="Ayın 1'i ile 31'i arasında bir gün seçin"
              />
            </v-col>
            <v-col sm="6">
              <rs-select
                :items="autoAssessmentTimingTypeList"
                :label="$t('labels.last_payment_timing_type')"
                :rules="[rules.required]"
                v-model="formData.last_payment_auto_assessment_timing_type_id"
                :readonly="disabled"
                :filled="disabled"
                required
              />
            </v-col>
            <v-col sm="6">
              <rs-text-field
                v-if="
                  [1, 2, 8, 9].indexOf(
                    formData.last_payment_auto_assessment_timing_type_id
                  ) > -1
                "
                type="number"
                min="1"
                max="31"
                step="1"
                :label="$t('labels.last_payment_timing_day')"
                v-model="formData.last_payment_day"
                :rules="[rules.required]"
                required
                :readonly="disabled"
                :filled="disabled"
                hint="Ayın 1'i ile 31'i arasında bir gün seçin"
              />
            </v-col>
            <v-col sm="6" v-if="formData.assessment_type_id === 1">
              <rs-money-field
                v-model="formData.amount_per_house"
                :label="$t('labels.debt_amount')"
                :rules="
                  disabled
                    ? []
                    : [
                        rules.min(formData.amount_per_house, 0.01),
                        rules.max(formData.amount_per_house, 9999999.99),
                      ]
                "
                :disabled="disabled"
              />
            </v-col>
            <v-col sm="6">
              <rs-select-project
                :label="$t('labels.project_code')"
                v-model="formData.project_id"
                :clearable="!disabled"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>
            <v-col sm="6">
              <rs-datepicker
                label="Geçerlilik Başlangıç Tarihi"
                v-model="formData.starts_on"
                :rules="disabled ? [] : [rules.required]"
                :disabled="disabled"
              />
            </v-col>
            <v-col sm="6">
              <rs-datepicker
                label="Geçerlilik Bitiş Tarihi"
                v-model="formData.ends_on"
                :disabled="disabled || !formData.starts_on"
                :rules="
                  disabled || !formData.starts_on
                    ? []
                    : [
                        rules.required,
                        rules.min(formData.ends_on, formData.starts_on),
                      ]
                "
                :min="formData.starts_on"
              />
            </v-col>
            <v-col sm="6">
              <rs-select
                :items="[
                  { id: 'daily', name: 'Günlük' },
                  { id: 'monthly', name: 'Aylık' },
                ]"
                :label="$t('labels.delay_calculation_type')"
                v-model="formData.deferment_calculation_period"
                :rules="[rules.required]"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>
            <v-col sm="6">
              <rs-text-field
                type="number"
                min="1"
                step="1"
                :label="$t('labels.delay_percentage')"
                v-model="formData.deferment_interest_percent"
                :rules="[rules.required]"
                :readonly="disabled"
                :filled="disabled"
                prefix="%"
              />
            </v-col>
            <v-col sm="6">
              <rs-text-field
                type="number"
                min="1"
                step="1"
                :label="$t('labels.delay_option_days')"
                v-model="formData.deferment_grace_period"
                :rules="[rules.required]"
                :readonly="disabled"
                :filled="disabled"
                suffix="Gün"
              />
            </v-col>
            <v-col sm="12">
              <rs-textarea
                :label="$t('labels.description')"
                :rules="[rules.required]"
                v-model="formData.description"
                :readonly="disabled"
                :filled="disabled"
                required
              />
            </v-col>
          </v-row>
          <rs-form-buttons
            :is-loading="isLoading"
            @cancel="handleCancelClick()"
            @submit.prevent="handleFormSubmit"
            hide-submit
            v-if="!disabled"
          />
        </v-form>
      </v-card-text>
    </v-card>

    <ActionLogList ref="actionLogList" />

    <v-card v-if="formData.assessment_type_id === 2">
      <v-card-text>
        <rs-action class="mb-3" @click="handleFillHouseTypeList">
          Bağımsız bölüm türlerinden doldur
        </rs-action>

        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>BB Tipi</th>
                <th>Tutar</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in houseTypes" :key="item.house_type_id">
                <td :disabled="item.disabled">
                  {{ item.house_type_name }}
                </td>
                <td :disabled="item.disabled">
                  <rs-money-field
                    v-model="item.amount"
                    :rules="
                      item.disabled || !editable
                        ? []
                        : [
                            rules.required,
                            rules.min(item.amount, 0.01),
                            rules.max(item.amount, 9999999.99),
                          ]
                    "
                    :disabled="item.disabled || !editable"
                  />
                </td>
                <td class="text-end">
                  <v-btn
                    @click="handleHouseTypeToggle(item)"
                    class="btn"
                    :color="item.disabled ? 'success' : 'pink'"
                    outlined
                    small
                    :disabled="!editable"
                  >
                    {{ item.disabled ? "Etkinleştir" : "İptal Et" }}
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card v-if="formData.assessment_type_id === 3">
      <v-card-text>
        <v-row dense class="mb-3">
          <v-col cols="12">
            <v-btn
              @click="handleTemplateDownloadClick"
              class="btn"
              color="pink"
              outlined
              small
              :disabled="!editable"
              :loading="isLoading"
            >
              XLSX şablon indir
            </v-btn>

            <v-btn
              @click="handleTemplateUploadClick"
              class="btn ms-2"
              color="pink"
              outlined
              small
              :disabled="!editable"
              :loading="isLoading"
            >
              XLSX şablon yükle
            </v-btn>

            <rs-file
              label="Dosya"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
              v-if="!disabled"
              @change="handleFileChange"
              class="d-none"
              id="importInput"
            />
          </v-col>
        </v-row>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>BB</th>
                <th>Tutar</th>
                <th class="text-end">
                  <v-btn
                    @click="handleToggleAllHouses()"
                    :disabled="!editable"
                    color="pink"
                    outlined
                    small
                  >
                    {{
                      houses.filter((item) => !item.disabled).length
                        ? "Tümünü İptal Et"
                        : "Tümünü Etkinleştir"
                    }}
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in houses" :key="item.house_id">
                <td :disabled="item.disabled">
                  {{ item.block_name }}-{{ item.door_number }}
                </td>
                <td :disabled="item.disabled">
                  <rs-money-field
                    v-model="item.amount"
                    :rules="
                      item.disabled || !editable
                        ? []
                        : [
                            rules.required,
                            rules.min(item.amount, 0.01),
                            rules.max(item.amount, 9999999.99),
                          ]
                    "
                    :disabled="item.disabled || !editable"
                  />
                </td>
                <td class="text-center">
                  <v-btn
                    @click="handleHouseToggle(item)"
                    :color="item.disabled ? 'success' : 'pink'"
                    outlined
                    small
                    :disabled="!editable"
                  >
                    {{ item.disabled ? "Etkinleştir" : "İptal Et" }}
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ActionLogList from "@/view/content/action-logs/ActionLogList";
import { mapActions, mapGetters } from "vuex";
import { hasForm, hasPermissions } from "@/view/mixins";

export default {
  name: "PeriodicForm",
  mixins: [hasForm, hasPermissions],
  computed: {
    ...mapGetters([
      "cluster",
      "clusterId",
      "autoAssessmentTimingTypeList",
      "incomeTypeList",
      "debitorTypeList",
    ]),
    filteredIncomes() {
      const newElements = this.incomeTypeList.filter((element) => {
        return element.id < 10;
      });
      return newElements;
    },
    houseTypeList() {
      return this.cluster && this.cluster.house_types;
    },
    editable() {
      if (this.disabled) {
        return false;
      }

      if (!this.id === null) {
        return true;
      }

      return true;
    },
  },
  components: {
    ActionLogList,
  },
  props: {
    budgetId: {
      type: Number,
      required: false,
      default: null,
    },
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    "formData.starts_on"(val) {
      if (!this.id) {
        if (val) {
          this.desc_param_date = new Date(val).toLocaleDateString("tr");
          if (this.desc_param_type) {
            this.formData.description =
              this.desc_param_type + " " + this.desc_param_date;
          } else {
            this.formData.description = this.desc_param_date;
          }
        }
      }
    },
    id() {
      this.load();
    },
    cluster: {
      handler(newValue) {
        if (this.id) {
          return;
        }

        if (newValue) {
          this.formData.deferment_calculation_period =
            this.cluster.deferment_calculation_period;
          this.formData.deferment_interest_percent =
            this.cluster.deferment_interest_percent;
          this.formData.deferment_grace_period =
            this.cluster.deferment_grace_period;
        }
      },
      immediate: true,
    },
    houseTypeList: {
      handler() {
        if (this.id) {
          return;
        }

        this.populateHouseTypeList();
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      }
      if (this.budgetId) {
        this.isLoading = true;
        Promise.all([this.loadBudget(), this.loadBudgetHouseTypes()]).finally(
          () => (this.isLoading = false)
        );
      }
    });
  },
  data() {
    return {
      desc_param_date: null,
      desc_param_type: null,
      income_type_id: null,
      isLoading: false,
      houseTypes: [],
      houses: [],
      types: [
        {
          id: 1,
          display_name: "Genel",
        },
        {
          id: 2,
          display_name: "BB Tip Bazında",
        },
        {
          id: 3,
          display_name: "Serbest",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    handleHouseTypeToggle(item) {
      item.disabled = !item.disabled;
      item.amount = null;
    },
    populateHouseTypeList() {
      const formList = [];

      if (this.id) {
        const currentData = [];
        if (this.formData.house_types) {
          for (const index in this.formData.house_types) {
            const item = this.formData.house_types[index];
            currentData[item.house_type_id] = item.amount;
          }
        }

        for (const index in this.houseTypeList) {
          if (!this.editable) {
            if (currentData[this.houseTypeList[index].id] === undefined) {
              continue;
            }
          }

          formList.push({
            house_type_id: this.houseTypeList[index].id,
            house_type_name: this.houseTypeList[index].name,
            amount: currentData[this.houseTypeList[index].id]
              ? currentData[this.houseTypeList[index].id]
              : null,
            disabled:
              currentData[this.houseTypeList[index].id] === undefined
                ? true
                : false,
          });
        }
      } else {
        for (const index in this.houseTypeList) {
          formList.push({
            house_type_id: this.houseTypeList[index].id,
            house_type_name: this.houseTypeList[index].name,
            amount: null,
            disabled: false,
          });
        }
      }

      this.houseTypes = formList.sort((a, b) =>
        a.house_type_name.localeCompare(b.house_type_name)
      );
    },
    setSelected(e) {
      if (e?.name) {
        this.desc_param_type = e.name;
        this.income_type_id = e.id;
        if (this.desc_param_date) {
          this.formData.description =
            this.desc_param_type + " " + this.desc_param_date;
        } else {
          this.formData.description = this.desc_param_type;
        }
      }
    },
    async loadBudget() {
      await this.$api
        .query(`budgets/${this.budgetId}`)
        .then((response) => {
          this.formData.starts_on = response.data.data.starts_on;
          this.formData.ends_on = response.data.data.ends_on;
          this.income_type_id = response.data.data.income_type_id;
          this.formData.assessment_type_id = 2;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async loadBudgetHouseTypes() {
      const params = {};

      params.budget_id = this.budgetId;
      params.per_page = 1000;
      params.order_by = "house_type_name";

      await this.$api
        .query(`budget-house-types`, { params })
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            this.houseTypes = response.data.data;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    load() {
      this.isLoading = true;

      return this.$api
        .query(`income/periodical-assessments/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);
          this.formData = Object.assign({}, response.data.data);
          this.updateClusterId(response.data.data.cluster_id);

          for (const index in this.formData) {
            if (
              response.data.data[index] !== undefined ||
              response.data.data[index] != null
            ) {
              this.formData[index] = response.data.data[index];
            }
          }

          if (!response.data.data.amount_per_house)
            delete this.formData.amount_per_house;

          if (response.data.data.house_types.length === 0)
            delete this.formData.house_types;

          delete this.formData.houses;

          this.income_type_id = response.data.data.income_type_id;
          if (response.data.data.assessment_type_id === 2) {
            this.populateHouseTypeList();
          } else if (response.data.data.assessment_type_id === 3) {
            this.populateHouseList();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);

      if (formData.assessment_type_id === 2) {
        formData.house_types = this.houseTypes.filter((item) => {
          return !item.disabled;
        });
      } else if (formData.assessment_type_id === 3) {
        formData.houses = this.houses.filter((item) => {
          return !item.disabled;
        });
      }

      formData.cluster_id = this.clusterId;
      formData.income_type_id = this.income_type_id;

      if (this.id) {
        this.$api
          .put(`income/periodical-assessments/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$nextTick(() => this.$emit("saved", response.data.data));
            if (event && event.closeOnSave) {
              this.handleCancelClick();
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`income/periodical-assessments`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$nextTick(() => this.$emit("saved", response.data.data));
            if (event && event.closeOnSave) {
              this.handleCancelClick();
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    showActionLogs() {
      this.$refs.actionLogList.show(
        "App\\Models\\Income\\PeriodicalAssessment",
        this.id
      );
    },
    handleFillHouseTypeList() {
      this.houseTypes = this.houseTypes.map((item) => {
        const found = this.houseTypeList.find(
          (v) => v.id === item.house_type_id
        );

        if (found && found.fee) {
          item.amount = found.fee;
        }

        return item;
      });
    },
    populateHouseList() {
      const params = {
        is_active: 1,
      };
      const formList = [];
      this.houses = [];

      this.isLoading = true;

      params.per_page = 1000;
      params.order_by = "door_number";
      params.order = "asc";

      this.$api
        .query(`clusters/${this.clusterId}/houses`, { params })
        .then((response) => {
          const currentData = {};
          if (this.originalData.houses) {
            for (const index in this.originalData.houses) {
              const item = this.originalData.houses[index];
              currentData[item.house_id] = item.amount;
            }
          }

          for (const index in response.data.data) {
            const item = response.data.data[index];
            if (!this.editable) {
              if (currentData[item.id] === undefined) {
                continue;
              }
            }

            formList.push({
              house_id: item.id,
              house_type_name: item.house_type_name,
              door_number: item.door_number,
              block_name: item.block_name,
              resident_to_debit: item.resident_to_debit,
              renter: item.renter,
              owner: item.owner,
              has_multiple_owners: item.has_multiple_owners,
              amount: currentData[item.id] ? currentData[item.id] : 0,
              disabled: currentData[item.id] === undefined ? true : false,
            });
          }

          this.houses = formList;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleHouseToggle(item) {
      item.disabled = !item.disabled;
      item.amount = null;
    },
    handleToggleAllHouses() {
      let value = false;

      if (this.houses.filter((item) => !item.disabled).length) {
        value = true;
      }

      for (const index in this.houses) {
        this.houses[index].disabled = value;
      }
    },
    handleAssessmentTypeChange(newValue) {
      if (newValue === 3) {
        this.populateHouseList();
      }
    },
    handleTemplateDownloadClick() {
      if (this.isLoading) return false;
      this.isLoading = true;

      const params = {
        is_active: 1,
        cluster_id: this.clusterId,
        debitor_type_id: this.formData.debitor_type_id,
      };

      if (this.formData.calculation_type_id === 2) {
        params.block_name = this.formData.block_ids;
      }

      this.$api
        .query("assessment-template", { params })
        .then((response) => {
          const anchor = document.createElement("a");
          anchor.href = response.data.data.url;
          anchor.target = "_blank";
          // anchor.download =
          //   response.data.data.title + "." + response.data.data.extension;
          anchor.click();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleTemplateUploadClick() {
      document.querySelector("input#importInput").value = null;
      document.querySelector("input#importInput").click();
    },
    handleFileChange(file) {
      if (!file) {
        return;
      }

      const allowedMimes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
      ];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.$toast.error("Sadece XLSX veya ODS yükleyebilirsiniz.");
        return;
      }

      this.isLoading = true;
      const data = new FormData();
      data.append("file", file);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$api
        .post("read-spreadsheet", data, config)
        .then((response) => {
          for (const index in response.data.data) {
            for (const houseIndex in this.houses) {
              if (
                this.houses[houseIndex].house_id ===
                parseInt(response.data.data[index][0])
              ) {
                const amount = parseFloat(response.data.data[index][6]);
                this.houses[houseIndex].amount = amount;

                this.houses[houseIndex].disabled = amount <= 0;
              }
            }
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
